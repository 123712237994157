<template>
  <div class="modal-content">
    <div class="modal-header">
      <button @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close"
              style="width: 35px; height: 35px; border-radius: 30px; font-size: 30px; background: gray;"><span
        aria-hidden="true">×</span></button>
      <h2 class="modal-title title-coupons lg-cond text-center ">{{$t('voucher.gotvoucher')}}</h2>
      <div class=" form-group">
        <input required type="text" v-bind:placeholder="$t('voucher.code')" name="couponcode" v-model="coupon" value=""
               class="form-control input-coupon-text">
        <button data-item-id="coupon-code-apply" data-item-type="tool" data-item-position="1" type="button" @click="applyCoupon()" class="btn btn-success col-xs-4 button-coupon-text t16 fw600">{{$t('apply')}}</button>
      </div>
      <div class="row" v-if="coupon != '' && $store.getters.coupon_message != ''">
        <div class="col-xs-12 ">
          <div class="message-text-error text-black">
            <div class="alert alert-danger2">
              <span class="text-red" v-if="coupon != '' && $store.getters.coupon_message != ''" v-html="$store.getters.coupon_message"></span>
              <span class="text-red" v-else-if="message != ''" > {{message}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('setCouponMessage', '')
    this.$cart.init(this)
    this.trackCoupon()
  },
  data () {
    return {
      message: '',
      coupon: ''
    }
  },
  watch: {
    coupon (newValue, oldValue) {
      this.$store.commit('setCouponMessage', '')
      this.message = ''
    }
  },
  methods: {
    trackCoupon () {
      let data = {
        'id': 'coupon-code-overlay',
        'name': 'coupon-code-overlay',
        'type': 'tool',
        'placement': 'floating',
        'items': [
          {
            'id': 'coupon-code-overlay-1',
            'type': 'tool',
            'position': '1'
          }
        ],
        'assets': {
          'tools': [
            {
              'id': 'coupon-code-overlay-1',
              'type': 'input-box',
              'name': 'coupon-code-overlay',
              'attributes': {
                'function': 'input-voucher-code'
              }
            },
            {
              'id': 'coupon-code-apply',
              'type': 'coupon',
              'name': 'coupon-code-apply',
              'attributes': {
                'function': 'coupon-code-apply'
              }
            }
          ]
        }
      }
      this.eventTrackingModuleLoad(data)
    },
    applyCoupon () {
      if (!this.coupon) {
        this.message = this.$t('validate_invalid_coupon')
        return false
      }
      let cart = this.$cart.get(this)
      let coupon = this._.cloneDeep(this.coupon)
      coupon = coupon.replace(/-/g, '')
      let data = {cart_id: cart.uuid, coupon_code: coupon.trim()}
      this.$cart.applyCoupon(this, data)
    }
  }
}
</script>

<style scoped>
  .alert-danger2 {
    color: #a94442;
    font-weight: bold;
  }

  .free-menu-description{
    cursor: pointer;
  }

  .v--modal-box {
    height: auto !important;
    top: 50px !important;
  }
  .modal-coupon .v--modal {
    min-width: 0px !important;
  }
</style>
