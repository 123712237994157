<template>
    <div class="modal-content">
        <h2 class="modal-title title-coupons lg-cond text-center pt-10 pb-10">
            {{ title }}
        </h2>
        <div class="control number">
            <button class="decrement-button" :disabled="decrementDisabled" @click="decrement">−</button>
            <button class="increment-button" :disabled="incrementDisabled" @click="increment">+</button>
            <input
                    type="text"
                    disabled="disabled"
                    :min="min"
                    :max="max"
                    :step="step"
                    v-model.number="currentValue"
                    @blur="currentValue = value"
                    @keydown.esc="currentValue = value"
                    @keydown.enter="currentValue = value"
                    @keydown.up.prevent="increment"
                    @keydown.down.prevent="decrement"
            />
            <!--:disabled="inputDisabled"-->
        </div>
        <div class="btn-group btn-group-justified" role="group">
            <button type="button" @click="$emit('close')"
                    class="btn btn-danger button-coupon-text t16 fw600">{{$t('close')}}
            </button>
            <button type="button" @click="update(currentValue)"
                    class="btn btn-success button-coupon-text t16 fw600">{{$t('btn_save')}}
            </button>
        </div>

    </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    value: {
      required: true
    },
    step: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      required: true
    },
    update: {
      type: Function
    }
  },
  data () {
    return {
      currentValue: this.value,
      decrementDisabled: false,
      incrementDisabled: false,
      inputDisabled: false
    }
  },
  watch: {
    value (val) {
      this.currentValue = val
    }
  },
  mounted () {
    if (this.value === this.min) {
      this.decrementDisabled = true
    }
  },
  methods: {
    increment () {
      if (this.disabled || this.incrementDisabled) {
        return
      }

      let newVal = this.currentValue + 1 * this.step
      this.decrementDisabled = false

      this._updateValue(newVal)
    },
    decrement () {
      if (this.disabled || this.decrementDisabled) {
        return
      }

      let newVal = this.currentValue + -1 * this.step
      this.incrementDisabled = false

      this._updateValue(newVal)
    },
    _updateValue (newVal) {
      const oldVal = this.currentValue

      if (oldVal === newVal || typeof this.value !== 'number') {
        return
      }
      if (newVal <= this.min) {
        newVal = this.min
        this.decrementDisabled = true
      }
      if (newVal >= this.max) {
        newVal = this.max
        this.incrementDisabled = true
      }
      this.currentValue = newVal
      this.$emit('input', this.currentValue)
    }
  }
}
</script>

<style scoped>
    .close {
        margin-top: 0;
    }

    button {
        margin: auto;
        width: 100%;
        margin-top: 20px;
    }

    .modal-notify {
        max-width: 400px;
        margin: auto;
        background: white;
        padding: 15px;
    }

    .alert-danger2 {
        color: #a94442;
        font-weight: bold;
    }

    .free-menu-description {
        cursor: pointer;
    }

    .v--modal-box {
        height: auto !important;
        top: 50px !important;
    }

    .modal-coupon .v--modal {
        min-width: 0px !important;
    }
</style>
