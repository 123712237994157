<template>
  <div class="chat-container">
    <div class="chat-logo">
      <a href="javascript:void(0);" class="chat-link" v-if="!isChatOpen && !isOnMobile" v-on:click="showChat"> 
        <img
          src="https://cdnva.s1gateway.com/file/get/WGloRGRFUVM4NUxTZlJCMDhUQUNhTGhnRFlqdk01WFlrNDBZblhZVy9HcFJuSkFiVGo1SnN1a09rck1yRHMxVlFpeURWbTFJdG5rNXAveDBJMW13L1E9PQ=="
          alt="Pizzahut Chat Icon"
        />
      </a>
    </div>
    <div class="chat-box" v-if="isChatOpen">
      <div class="hide-chat-btn" v-if="hasBeenLoaded" v-on:click="hideChat">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
      <iframe
        id="s1chat_iframe"
        className="s1chat_iframe"
        src="https://voices.s1gateway.com/pe/35ea813b8f2d7d01e49f1d0227f7dd0f8c3316d96a3f2d7d2badec792c8ed676f6ce748cbff0ce1b"
        frameBorder="0"
        title="chat-widget"
        key="chat-iframe"
      />
    </div>
  </div>
</template>
<script>

function isOnMobile() {
  return window.matchMedia("only screen and (max-width: 992px)").matches
}

export default {
  created () { },
  data () {
    return {
      isOnMobile: isOnMobile(),
      isChatOpen: false,
      hasBeenLoaded: false
    }
  },
  watch: { },
  computed: { },
  mounted () {
    window.showChat = this.showChat;
  },
  methods: {
    showChat () {
      this.isChatOpen = true
      this.eventTrackingModuleItemClick({
        id: 'chat-link',
        moduleId: 'pop-home'
      })
      setTimeout(() => {
        var iframe = document.getElementById('s1chat_iframe')
        var chatContainerElement = document.getElementsByClassName('chat-container')[0]
        chatContainerElement.style.display = 'block'
        iframe.onload = () => {
          this.hasBeenLoaded = true
        }
        setTimeout(() => {
          this.hasBeenLoaded = true
        }, 1000)
      }, 500);
    },
    hideChat () {
      this.isOnMobile = isOnMobile()
      this.isChatOpen = false
      this.hasBeenLoaded = false
    }
  }
}
</script>
<style scoped>
  .chat-container {
    display: none;
    position: fixed;
    z-index: 9999;
    right: 20px;
    left: initial;
    bottom: 128px;
  }
  .chat-container .chat-box {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block !important;
  }
  .chat-container .chat-box iframe {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-height: 800px) {
    .chat-container {
      bottom: 15%;
    }
  }

  @media only screen and (min-width: 992px) {
    .chat-container {
      display: block !important;
    }

    .chat-container .chat-box {
      width: 320px;
      height: 500px;
    }
  }

  @media only screen and (min-height: 1336px) {
    .chat-container {
      bottom: 32%;
    }
  }

  .hide-chat-btn {
    position: absolute;
    display: block;
    cursor: pointer;
    z-index: 2;
    color: white;
    right: 10px;
    top: 5px;
    font-size: 22px;
  }

  .chat-logo {
    width: 50px;
    height: 50px;
  }

  .chat-logo a {
    display: block;
  }

  .chat-logo img {
    width: 50px;
    height: 50px;
  }
</style>