<template>
  <div class="right-cart" v-bind:class="{ open: isCartOpen }">
    <div class="cart-inner" style="">
      <div id="basket" class="pt-20 pt15 px-10">
        <div class="jagged"></div>
        <div class="cart-contain">
          <div class="h-full flex flex-col">
            <div class=" close-cart" style="">
              <a href="javascript: void(0);" @click="showHideCart(false)">{{
                $t('button_close')
              }}</a>
            </div>
            <div class="heading-dotted p10 ">
              <span class="lg-cond t24" :data-synth="'cart-title'">{{
                user_action === ADD_TO_CART || user_action === ADD_TO_CART_UPSELL || user_action === REMOVE_FROM_CART ? $t('one_moment_dot3') : $t('cart.your_basket')
              }}</span>
            </div>
            <!--<div class="basket-memo">{{$t('cart.make_change')}}</div>-->
            <div class="cart-item-contain pb-10" data-synth="cart-items" style="">
              <div class="cart-item t13 67" data-original-title="" title="">
                <p
                  class="pl-10 pt-10 pr-10 typo-l7"
                  v-if="cart.hasOwnProperty('data') && cart.data.length === 0"
                >
                  {{ $t('cart.your_basket_empty_1') }}
                  <span v-html="$t('cart.your_basket_empty_2')"></span>
                </p>
                <!-- ##########################################################-->
                <div class="main-item">
                  <transition-group name="fade1">
                    <div
                      v-for="data in cart.data"
                      :key="genUniqueKey(data)"
                      class="flex1 per-item"
                      data-synth="cart-menu-item"
                      v-bind:item="data.id"
                    >
                      <!-- ################# Show combo item ############# -->
                      <div
                        href="javascript: void(0)"
                        @click.stop="editCombo(data)"
                        v-if="data.product_type === $const.CARD_ITEM_TYPE.COMBO"
                        class="cursor-pointer"
                      >
                        <div class="fw600 py-10" data-synth="cart-menu-item">
                          <span class="block-left">
                            <span
                              class="menu-price-sub"
                              v-if="data.quantity > 1"
                              >{{
                                $t('format_product_not_quantity', {
                                  product_name: data.name || '',
                                  quantity: data.quantity
                                })
                              }}
                            </span>
                            <span class="menu-price-sub" v-else>{{
                              data.name
                            }}</span>
                          </span>
                          <div
                            class="cart-quantity"
                            v-on:click="ModifyQuantity(data, $event)"
                          >
                            x {{ data.quantity }}
                          </div>
                          <div class=" block-right block-top">
                            <span>
                              {{ $const.CURRENCY
                              }}{{ formatNumDisplay(data.sub_total)
                              }}<a
                                @click.prevent.stop="removeCart(data)"
                                href="javascript:void(0)"
                                class="cart-remove"
                              >
                                <img
                                  src="../../assets/images/icons/remove.svg"
                                  alt="remove"
                                />
                                <!--<i class="fas fa-trash"></i>-->
                              </a>
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div
                          class=""
                          v-for="item in data.items"
                          v-bind:key="item.id"
                          v-bind:item="item"
                        >
                          <div
                            v-for="gItem in item.group_items"
                            v-bind:key="gItem.group_id"
                          >
                            <div class="content  cl-78838c">
                              <div class="fw600 basket-item-product">
                                <span
                                  class="flex justify-between items-center mr-30"
                                  >{{
                                    (gItem.layer_info
                                      ? gItem.layer_info.first
                                        ? gItem.layer_info.first.name
                                        : ''
                                      : '') +
                                      ' ' +
                                      getComboItemName(gItem, data)
                                  }}</span
                                >
                              </div>
                            </div>
                            <div
                              class="content cl-78838c"
                              v-if="gItem.layer_info && gItem.layer_info.second"
                            >
                              <span class="block-left"
                                ><span class="menu-price-sub">{{
                                  gItem.layer_info.second.name
                                    ? gItem.layer_info.second.name
                                    : ''
                                }}</span></span
                              >
                              <!-- <div class="fw600 block-right t13 menu-price-sub"
                                   v-if="gItem.layer_info.second.price > 0">
                                <span>{{$const.CURRENCY}} {{formatNumDisplay(gItem.layer_info.second.price)}}</span>
                              </div> -->
                              <div class="clearfix"></div>
                            </div>
                            <div v-if="gItem.toppings != null">
                              <div
                                v-for="subitem in gItem.toppings.extra"
                                :key="genUniqueKey(subitem)"
                                class="content  cl-78838c"
                                v-if="isSpecialTopping(subitem)"
                              >
                                <span class="block-left"
                                  ><span class="menu-price-sub">{{
                                    subitem.name
                                  }}</span></span
                                >
                                <div
                                  class="fw600 block-right t13 menu-price-sub cl-78838c"
                                  v-if="subitem.price > 0"
                                >
                                  <span
                                    >{{ $const.CURRENCY
                                    }}{{
                                      formatNumDisplay(subitem.price)
                                    }}</span
                                  >
                                </div>
                                <div class="clearfix"></div>
                              </div>
                              <div
                                v-for="subitem in gItem.toppings.extra"
                                :key="genUniqueKey(subitem)"
                                class="content  cl-78838c"
                                v-if="!isSpecialTopping(subitem)"
                              >
                                <span class="block-left">
                                  <span
                                    class="menu-price-sub"
                                    v-if="subitem.quantity <= 1"
                                    >{{
                                      $t('cart.add') + ' ' + subitem.name.split('-')[0]
                                    }}</span
                                  >
                                  <span class="menu-price-sub" v-else>{{
                                    $t('format_product', {
                                      quantity: subitem.quantity,
                                      product_name:
                                        $t('cart.add') + ' ' + subitem.name.split('-')[0]
                                    })
                                  }}</span>
                                </span>
                                <div
                                  class="fw600 block-right t13 menu-price-sub cl-78838c"
                                  v-if="subitem.price > 0"
                                >
                                  <span
                                    >{{ $const.CURRENCY
                                    }}{{
                                      formatNumDisplay(subitem.price)
                                    }}</span
                                  >
                                </div>
                                <div class="clearfix"></div>
                              </div>
                              <div
                                v-for="subitem in gItem.toppings.remove"
                                :key="genUniqueKey(subitem)"
                                class="content  cl-78838c"
                              >
                                <span class="block-left"
                                  ><span class="menu-price-sub">{{
                                    $t('cart.remove') + ' ' + subitem.name
                                  }}</span></span
                                >
                                <div class="clearfix"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- ################# Show combo item ############# -->

                      <!-- ################# Show single item ############# -->
                      <div
                        v-if="
                          data.product_type === $const.CARD_ITEM_TYPE.SINGLE
                        "
                        @click.stop="editSingle(data)"
                        class="cursor-pointer"
                      >
                        <div class="fw600 py-10">
                          <span class="block-left">
                            <span class="menu-price-sub">
                              {{ sizesToIgnore.some(s => data.name.indexOf(s) === 0) ? data.name : formatName(data) }}
                            </span>
                          </span>
                          <div
                            class="cart-quantity"
                            v-on:click="ModifyQuantity(data, $event)"
                          >
                            x {{ data.quantity }}
                          </div>
                          <div class=" block-right block-top">
                            <span class="top-price">
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(data.total)
                                })
                              }}
                              <a
                                @click.prevent.stop="removeCart(data)"
                                href="javascript:void(0)"
                                class="cart-remove"
                              >
                                <img
                                  src="../../assets/images/icons/remove.svg"
                                  alt="remove"
                                />
                                <!--<i class="fa fa-trash"></i>-->
                              </a>
                            </span>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div
                          class="content  cl-78838c"
                          v-if="
                            data.items.length > 0 &&
                              data.items[0].layer_info.second
                          "
                        >
                          <span class="block-left"
                            ><span class="menu-price-sub">{{
                              data.items[0].layer_info.second.name
                                ? data.items[0].layer_info.second.name
                                : ''
                            }}</span></span
                          >
                          <div
                            class="fw600 block-right t13 menu-price-sub cl-78838c"
                            v-if="data.items[0].layer_info.second.price > 0"
                          >
                            <span>
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(
                                    data.items[0].layer_info.second.price
                                  )
                                })
                              }}
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                        <div
                          v-if="
                            data.items[0].toppings != null &&
                              data.items[0].toppings.extra
                          "
                        >
                          <div
                            v-for="subitem in data.items[0].toppings.extra"
                            :key="genUniqueKey(subitem)"
                            class="content  cl-78838c"
                            v-if="isSpecialTopping(subitem)"
                          >
                            <span class="block-left"
                              ><span class="menu-price-sub">{{
                                subitem.name
                              }}</span></span
                            >
                            <div
                              class="fw600 block-right t13 menu-price-sub cl-78838c"
                              v-if="subitem.price > 0"
                            >
                              <span>
                                {{
                                  $t('currency_display', {
                                    currency: $const.CURRENCY,
                                    amount: formatNumDisplay(subitem.price)
                                  })
                                }}
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>

                          <div
                            class="topping-box"
                            v-if="
                              data.items[0].toppings.extra &&
                                data.items[0].toppings.extra.length
                            "
                          >
                            <div
                              v-for="subitem in data.items[0].toppings.extra"
                              :key="genUniqueKey(subitem)"
                              class="content  cl-78838c"
                              v-if="!isSpecialTopping(subitem)"
                            >
                              <span class="block-left">
                                <span
                                  class="menu-price-sub"
                                  v-if="subitem.quantity <= 1"
                                  >{{ $t('cart.prefix_add') }}
                                  {{ subitem.name.split('-')[0] }}</span
                                >
                                <span class="menu-price-sub" v-else>{{
                                  $t('format_product', {
                                    quantity: subitem.quantity,
                                    product_name: subitem.name.split('-')[0]
                                  })
                                }}</span>
                              </span>
                              <div
                                class="fw600 block-right t13 menu-price-sub cl-78838c"
                                v-if="subitem.price > 0"
                              >
                                <span>
                                  {{
                                    $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(subitem.price)
                                    })
                                  }}
                                </span>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>

                          <div
                            class="topping-box"
                            v-if="
                              data.items[0].toppings.remove &&
                                data.items[0].toppings.remove.length
                            "
                          >
                            <div
                              v-for="subitem in data.items[0].toppings.remove"
                              :key="genUniqueKey(subitem)"
                              class="content  cl-78838c"
                            >
                              <span class="block-left"
                                ><span class="menu-price-sub"
                                  >{{ $t('cart.prefix_remove') }}
                                  {{ subitem.name.split('-')[0] }}</span
                                ></span
                              >
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- ################# Show single item ############# -->

                      <!-- ################# Show half item ############# -->
                      <div
                        v-if="data.product_type === $const.CARD_ITEM_TYPE.HALF"
                        @click.stop="editSingle(data, true)"
                      >
                        <div class="fw600 py-10">
                          <span class="block-left">
                            <span
                              class="menu-price-sub"
                              v-if="data.quantity > 1"
                              >{{ formatName(data) }}</span
                            >
                            <span class="menu-price-sub" v-else>{{
                              data.name
                            }}</span>
                          </span>
                          <div
                            class="cart-quantity"
                            v-on:click="ModifyQuantity(data, $event)"
                          >
                            x {{ data.quantity }}
                          </div>
                          <div class=" block-right block-top">
                            <span class="top-price">
                              {{
                                $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(data.sub_total)
                                })
                              }}
                              <a
                                @click.prevent.stop="removeCart(data)"
                                href="javascript:void(0)"
                                class="cart-remove"
                              >
                                <img
                                  src="../../assets/images/icons/remove.svg"
                                  alt="remove"
                                />
                              </a>
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <!-- show left -->
                        <div
                          class=""
                          v-if="data.items.length > 0"
                          v-bind:key="data.items[0].id"
                          v-bind:item="data.items[0]"
                        >
                          <div
                            class="cart-half content  cl-78838c"
                            v-if="data.items[0].name"
                          >
                            <div class="half-item">
                              <span class="fw600 block-left">
                                <span class="menu-left-sub">
                                  <!--
                                  {{ $t('left_half')+' ('+((data.items.length > 0 && data.items[0].layer_info) ? data.items[0].layer_info.first_name : '')+' '+(data.items[0].name)+')' }}
                                   -->
                                  {{ $t('left_half') }}
                                </span>
                              </span>
                              <div class=" block-right">
                                <span class="">
                                  {{
                                    $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(
                                        data.items[0].layer_info.first.price
                                      )
                                    })
                                  }}
                                </span>
                              </div>
                            </div>

                            <div class="half-sub-item">
                              <div class="content  cl-78838c">
                                <span class="block-left">
                                  <span class="menu-price-sub">
                                    {{ formatName(data.items[0], true) }}
                                  </span>
                                </span>
                                <div class="clearfix"></div>
                              </div>
                            </div>

                            <div class="half-sub-item">
                              <div class="content  cl-78838c">
                                <span class="block-left"
                                  ><span class="menu-price-sub">{{
                                    data.items.length > 0 &&
                                    data.items[0].layer_info
                                      ? data.items[0].layer_info.second_name
                                      : ''
                                  }}</span></span
                                >
                                <div class="clearfix"></div>
                              </div>
                            </div>

                            <div class="half-topping">
                              <div
                                v-if="
                                  data.items[0].toppings != null &&
                                    data.items[0].toppings.extra
                                "
                              >
                                <div
                                  v-for="subitem in data.items[0].toppings
                                    .extra"
                                  :key="genUniqueKey(subitem)"
                                  class="content  cl-78838c"
                                  v-if="isSpecialTopping(subitem)"
                                >
                                  <span class="block-left"
                                    ><span class="menu-price-sub">{{
                                      subitem.name
                                    }}</span></span
                                  >
                                  <div
                                    class=" block-right"
                                    v-if="subitem.price > 0"
                                  >
                                    <span class=""
                                      >+ {{ $const.CURRENCY
                                      }}{{ formatNumDisplay(subitem.price) }}
                                    </span>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                                <div
                                  v-for="subitem in data.items[0].toppings
                                    .extra"
                                  :key="genUniqueKey(subitem)"
                                  class="content  cl-78838c"
                                  v-if="!isSpecialTopping(subitem)"
                                >
                                  <span class="block-left">
                                    <span
                                      class="menu-price-sub"
                                      v-if="subitem.quantity <= 1"
                                      >{{ subitem.name }}</span
                                    >
                                    <span class="menu-price-sub" v-else>{{
                                      $t('format_product', {
                                        quantity: subitem.quantity,
                                        product_name: subitem.name
                                      })
                                    }}</span>
                                  </span>
                                  <div
                                    class=" block-right"
                                    v-if="subitem.price > 0"
                                  >
                                    <span class="">
                                      {{
                                        $t('currency_display', {
                                          currency: $const.CURRENCY,
                                          amount: formatNumDisplay(
                                            subitem.price
                                          )
                                        })
                                      }}
                                    </span>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                                <div
                                  v-if="
                                    data.items[0].toppings &&
                                      data.items[0].toppings.remove
                                  "
                                >
                                  <div
                                    v-for="subitem in data.items[0].toppings
                                      .remove"
                                    :key="genUniqueKey(subitem)"
                                    class="content  cl-78838c"
                                  >
                                    <span class="block-left"
                                      ><span class="menu-price-sub">{{
                                        $t('cart.remove') + ' ' + subitem.name
                                      }}</span></span
                                    >
                                    <div class="clearfix"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- show left -->

                        <!-- show right -->
                        <div
                          v-if="data.items.length > 0"
                          v-bind:key="data.items[1].id"
                          v-bind:item="data.items[1]"
                        >
                          <div
                            class="cart-half content  cl-78838c"
                            v-if="data.items[1].name"
                          >
                            <div class="half-item">
                              <span class="fw600 block-left"
                                ><span class="menu-right-sub">
                                  <!--{{$t('right_half')+' ('+((data.items.length > 0 && data.items[1].layer_info) ? data.items[1].layer_info.first_name : '')+' '+(data.items[1].name)+')'}}-->
                                  {{ $t('right_half') }}
                                </span></span
                              >
                              <div class=" block-right">
                                <span class="">
                                  {{
                                    $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(
                                        data.items[1].layer_info.first.price
                                      )
                                    })
                                  }}
                                </span>
                              </div>
                            </div>

                            <div class="half-sub-item">
                              <div class="content  cl-78838c">
                                <span class="block-left">
                                  <span class="menu-price-sub">{{
                                    formatName(data.items[1], true)
                                  }}</span>
                                </span>
                                <div class="clearfix"></div>
                              </div>
                            </div>

                            <div class="half-sub-item">
                              <div class="content  cl-78838c">
                                <span class="block-left"
                                  ><span class="menu-price-sub">{{
                                    data.items.length > 0 &&
                                    data.items[1].layer_info
                                      ? data.items[1].layer_info.second_name
                                      : ''
                                  }}</span></span
                                >

                                <div class="clearfix"></div>
                              </div>
                            </div>

                            <div class="half-topping">
                              <div
                                v-if="
                                  data.items[1].toppings != null &&
                                    data.items[1].toppings.extra
                                "
                              >
                                <div
                                  v-for="subitem in data.items[1].toppings
                                    .extra"
                                  :key="genUniqueKey(subitem)"
                                  class="content  cl-78838c"
                                  v-if="isSpecialTopping(subitem)"
                                >
                                  <span class="block-left">
                                    <span class="menu-price-sub">{{
                                      subitem.name
                                    }}</span>
                                  </span>
                                  <div
                                    class=" block-right"
                                    v-if="subitem.price > 0"
                                  >
                                    <span class="">
                                      {{
                                        $t('currency_display', {
                                          currency: $const.CURRENCY,
                                          amount: formatNumDisplay(
                                            subitem.price
                                          )
                                        })
                                      }}
                                    </span>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>
                                <div
                                  v-for="subitem in data.items[1].toppings
                                    .extra"
                                  :key="genUniqueKey(subitem)"
                                  class="content  cl-78838c"
                                  v-if="!isSpecialTopping(subitem)"
                                >
                                  <span class="block-left">
                                    <span
                                      class="menu-price-sub"
                                      v-if="subitem.quantity <= 1"
                                      >{{ subitem.name }}</span
                                    >
                                    <span class="menu-price-sub" v-else>{{
                                      $t('format_product', {
                                        quantity: subitem.quantity,
                                        product_name: subitem.name
                                      })
                                    }}</span>
                                  </span>
                                  <div
                                    class=" block-right"
                                    v-if="subitem.price > 0"
                                  >
                                    <span class="">
                                      {{
                                        $t('currency_display', {
                                          currency: $const.CURRENCY,
                                          amount: formatNumDisplay(
                                            subitem.price
                                          )
                                        })
                                      }}
                                    </span>
                                  </div>
                                  <div class="clearfix"></div>
                                </div>

                                <div
                                  v-if="
                                    data.items[1].toppings &&
                                      data.items[1].toppings.remove
                                  "
                                >
                                  <div
                                    v-for="subitem in data.items[1].toppings
                                      .remove"
                                    :key="genUniqueKey(subitem)"
                                    class="content  cl-78838c"
                                  >
                                    <span class="block-left"
                                      ><span class="menu-price-sub">{{
                                        subitem.name
                                      }}</span></span
                                    >
                                    <div class="clearfix"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- show right -->
                      </div>
                      <!-- ################# Show half item ############# -->
                    </div>
                  </transition-group>
                </div>

                <!-- ##########################################################-->
                <div
                  class="per-item item-coupon no-remove pl-10 pt-10 pr-10 t14 fw600"
                  v-if="
                    cart.sub_total != cart.total &&
                      (formatPercent(cart.tax_percent) > 0 ||
                        (cart.coupon && cart.coupon.total_discount > 0))
                  "
                >
                  <div class="display-flex" style="width: 100%">
                    <div class="block-left flex1 display-flex flex-row">
                      {{ $t('cart.subtotal') }}
                    </div>
                    <div class="block-right ">
                      <span class="t16"
                        >{{ $const.CURRENCY }}
                        <span class="t16 " id="sub_total_price">
                          {{ formatNumDisplay(cart.sub_total) }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="per-itemp item-coupon no-remove l-10 pt-10 t14 cl-78838c"
                  v-if="$const.FLAG_DELIVERY_FEE"
                >
                  <div class="display-flex" style="width: 100%">
                    <div class="block-left flex1 display-flex flex-row">
                      {{ $t('cart.delivery_fee') }}
                    </div>
                    <div class="block-right">
                      <span
                        >{{ $const.CURRENCY
                        }}{{ formatNumDisplay(cart.delivery_fee) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="cart.coupon"
                  class="t14 fw600 coupon-cart-item"
                  style="display: block"
                >
                  <div
                    v-for="coupon in cart.coupon.data"
                    :key="genUniqueKey(coupon)"
                    class="per-item item-coupon "
                    style="width: 100%"
                  >
                    <div
                      v-if="
                        !lodash.includes(
                          $const.COUPON_TYPES.CIRCLE_DISCOUNT,
                          coupon.discount_type
                        )
                      "
                      class="content"
                    >
                      <div class="block-left flex1 display-flex flex-row">
                        {{ $t('cart.discount') + ': ' }}
                        <span class="code">{{ coupon.code }}</span>
                      </div>

                      <div
                        v-if="
                          coupon.discount_quantity &&
                            coupon.discount_quantity > 0
                        "
                        class="cart-quantity"
                      >
                        x {{ coupon.discount_quantity }}
                      </div>
                      <div class="block-right flex1 display-flex flex-row">
                        <div
                          v-if="
                            coupon.discount_type ===
                              $const.COUPON_TYPE_FREE_DELIVERY
                          "
                          class="menu-price-sub"
                        >
                          <span class="t16">{{
                            $t('cart.free_delivery')
                          }}</span>
                        </div>
                        <div
                          v-else-if="
                            coupon.discount_type != $const.COUPON_TYPE_ITEM &&
                              coupon.circle_discount_type != 'free_item'
                          "
                          class="menu-price-sub"
                        >
                          <div class="t16">
                            {{ $const.CURRENCY
                            }}<span class="t16">{{
                              formatNumDisplay(coupon.discount_value)
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <a
                        @click="removeCoupon(coupon.code)"
                        href="javascript:void(0)"
                        class="cart-remove"
                        v-if="!coupon.is_auto"
                      >
                        <img
                          src="../../assets/images/icons/remove.svg"
                          alt="remove"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cart-total t16 fw600" v-if="cart.total > 0">
                <div class="display-flex">
                  <div class="flex1">{{ $t('cart.total') }}</div>
                  <span class="t18">
                    {{
                      $t('currency_display', {
                        currency: $const.CURRENCY,
                        amount: formatNumDisplay(cart.total)
                      })
                    }}
                  </span>
                </div>
              </div>
              <div
                class="p5 t14 fw600"
                v-if="cart.total > 0 && formatPercent(cart.tax_percent) > 0"
              >
                <div class="display-flex">
                  <div class="flex1">{{ $t('cart.service_tax') }}</div>
                  <span class="t16">
                    {{
                      $t('currency_display', {
                        currency: $const.CURRENCY,
                        amount: formatNumDisplay(cart.tax_total)
                      })
                    }}
                  </span>
                </div>
              </div>
              <div class="padding-left-right-5 mt-10">
                <div
                  class="coupon-code-text cl-78838c"
                  @click="showModalVoucher"
                >
                  {{ $t('cart.got_voucher_code') }}
                </div>

                <!-- <a v-if="user"
                   v-on:click="showHideCart(false);redirectTo('category', {slug: $const.HEADER_MENU.COUPON})"
                   class="choose-coupon-list text-center mt-10 xs-block text-black">
                  {{ $t('choose_coupon_list') }}
                </a> -->
              </div>
            </div>
            <!-- hide for temporary v-if="false" -->
            <div
              class="addons-cart"
              v-bind:style="{ bottom: addonsBottom + 'px' }"
              v-if="false"
            >
              <div class="hungry-title container-shake-animation">
                {{ $t('hungry_more') }}
              </div>
              <!--##############################-Silder-####################### -->
              <vueper-slides
                :autoplay="false"
                ref="myCarousel"
                :touchable="false"
                :bullets="false"
                pauseOnHover
              >
                <vueper-slide v-for="slide in slides" :key="slide.id">
                  <div slot="slideContent">
                    <div
                      class="item"
                      style="align-items: center;justify-content: center;"
                    >
                      <div class="image" style="float: left;">
                        <img style="" :alt="slide.name" v-bind:src="slide.imagelink" />
                      </div>
                      <div class="flag flag--" style="float: left;">
                        <span class="flag__inner"></span>
                      </div>
                      <div class="name" style="float: left;">
                        <div class="text-contain">
                          <h5 class="t15 fw600 text-item text-black" style="">
                            {{ slide.name }}
                          </h5>
                        </div>
                        <div class="" style="margin-bottom: 5px;">
                          <select
                            name="base"
                            class="select-dropdown baseOption noAddon hide"
                            id="base"
                          ></select>
                        </div>
                      </div>
                      <div class="price" style="">
                        <div class="text-contain">
                          <div class="text-item">
                            <div class=" phPrice t15 fw600 text-black" style="">
                              <p>
                                <span>{{ $const.CURRENCY }}</span
                                >{{ slide.promotionprice }}
                              </p>
                            </div>
                            <div
                              class="virtual-price t13 fw600 cl-78838c "
                              style=""
                            >
                              <strike
                                ><span>{{ $const.CURRENCY }}</span
                                >{{ slide.baseprice }}</strike
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="button" style="">
                        <div class="text-contain" style="width: auto;">
                          <div class=" text-item">
                            <button
                              class="btn btn-success btn-add-single-item-addon t13 "
                              @click="AddToCart(slide)"
                              style="padding: 8px 7px"
                            >
                              {{ $t('button_add_now') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </vueper-slide>
              </vueper-slides>
              <!--##############################-Silder-####################### -->
              <a
                @click="$refs.myCarousel.previous()"
                class="left carousel-control"
                href="#"
                data-slide="prev"
                style="width: 25px;"
              >
                <span class="glyphicon glyphicon-chevron-left"></span>
                <span class="sr-only">{{ $t('button_previous') }}</span>
              </a>
              <a
                @click="$refs.myCarousel.next()"
                class="right carousel-control"
                href="#"
                data-slide="next"
                style="width: 25px;"
              >
                <span class="glyphicon glyphicon-chevron-right"></span>
                <span class="sr-only">{{ $t('button_next') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-checkout checkout-out  margin-bottom">
        <!-- Show Upsell Model -->
        <div class="pt-10 upsellCart" v-if="showUpsell">
          <div class="typography-3 text-grey-dark opacity-90 pl-5 pb-10">
            <span class="upsell-header">{{ $t('upsell-cart') }}</span>
          </div>
          <div
            class="mb-10  cursor-pointer shadow-down-2 flex items-center bg-white overflow-hidden rounded-3"
            style="height: '60px'"
            v-on:click.stop="addToBasket"
          >
            <div
              class="mr-10"
              :style="{
                width: '60px',
                height: '60px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: backgroundImage
              }"
            ></div>
            <div class="flex-1 mr-10 text-12">
              <div
                class="typography-6 text-grey-darker flex justify-between text-15 bold leading-tight"
              >
                <div style="display:inline">{{ upsellData.name }}</div>
                <div style="display:inline">
                  {{ $const.CURRENCY }}{{ formatNum(upsellData.display_price) }}
                </div>
              </div>
              <div
                class="typography-7 text-grey flex justify-between leading-tight"
              >
                <ellipsis
                  :data="upsellData.marketing_description"
                  :line-clamp="1"
                ></ellipsis>
              </div>
            </div>
            <button
              class="px-15 ml-auto text-white text-12 h-full bg-green bold"
              style="height:60px;"
              :class="{ 'btn-upsell-disabled': loading }"
              :disabled="loading"
            >
              {{ $t('button_add') }}
            </button>
          </div>
        </div>
        <div style="width: 100%; margin: auto">
          <button
            class="btn btn-success show-basket "
            v-if="is_mobile && (!isCartOpen || cart.total < 0)"
            @click="showHideCart(true)"
          >
            <div
              class="float-l min-cart"
              v-if="cart.data != null && getTotalItem() > 0"
            >
              <span class="num" data-synth="cart-item-count">{{ cart.data ? getTotalItem() : 0 }}</span>
            </div>
            {{ $t('button_view_basket') }}
            <span class="float-r" v-if="cart.total > 0"
              >{{ $const.CURRENCY }}{{ formatNumDisplay(cart.total) }}</span
            >
          </button>
          <div
            v-else
            class="display-flex group-button-cart-checkout flex-column"
          >
            <button
              class="btn btn-success btn-checkout-cart item-btn"
              data-synth="checkout-button"
              @click="checkout"
            >
              <div
                class="float-l min-cart"
                v-if="cart.data != null && getTotalItem() > 0"
              >
                <span class="num" data-synth="cart-item-count">{{ cart.data ? getTotalItem() : 0 }}</span>
              </div>
              {{ $t('button_checkout') }}
            </button>
            <p class="text-center item-btn t13 mt-5" v-if="!checkMinCart()">
              {{
                $t('format_mincart', {
                  currency: $const.CURRENCY,
                  amount: getRequiredMinCart()
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../../assets/css/vueperslides.min.css'
import { MAPPED_ACTIONS } from '../../utils/map-actions'
import voucher from '../popup/ModalVoucher.vue'
import ModalPopup from '../popup/ModalPizza'
import ModalUpdateCart from '../popup/ModalUpdateCart'
import ModalUpsellConfirmation from '../popup/ModalUpsellConfirmation'
import Ellipsis from '@/components/partials/Ellipsis'
import * as action from '../../store/actions'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  components: {
    Ellipsis
  },
  mounted() {
    var vm = this
    this.$cart.init(this)
    this.$root.$on('cartUpdate', () => {
      vm.$cart.init(vm)
      vm.updateDealsDetails()
      vm.loadUpsellProduct()
    })
    this.$root.$on('couponItemImplement', data => {
      if (data) {
        if (data.is_combo) {
          vm.$router.push({
            name: 'combo-detail',
            params: {
              id: data.menu[0].uuid,
              ssid: 'coupon',
              coupon: data.coupon
            }
          })
        } else {
          vm.show(data.menu[0], '', data.coupon)
        }
      }
    })
    this.$root.$on('onRedirectToHome', () => {
      vm.$router.push({ name: 'home' })
    })
    this.$root.$on('cartAddItem', data => {
      vm.AddToCart(data)
    })

    this.$root.$on('showHideCart', flag => {
      vm.showHideCart(flag)
    })
    window.addEventListener('resize', function(e) {
      vm.resizeCart()
      vm.is_mobile = vm.isMobile()
    })
    vm.is_mobile = vm.isMobile()
    vm.resizeCart()
    vm.loadUpsellProduct()
  },
  data() {
    return {
      isShowBtnCheckOut: false,
      isCartOpen: false,
      is_mobile: false,
      addonsBottom: -15,
      slides: [],
      showUpsell: false,
      upsellData: {},
      upsellCategoryName: '',
      upsellCategoryOrder: ['drinks', 'appetizers', 'desserts'],
      backgroundImage: 'url()',
      dealsDetails: {},
      totalDeals: [],
      sizesToIgnore: ['Gran Hut', 'Big Hut'],
      lang: this.$session.get('lang') || process.env.VUE_APP_DEFAULT_LANGUAGE,
      ...MAPPED_ACTIONS
    }
  },
  computed: {
    ...mapState(['loading', 'user_action']),
    ...mapGetters({
      selectedProductByCart: 'selectedProductByCart',
      user: 'user',
    }),
    isCouponBypassingMinimumCart: function() {
      return (this.cart.coupon && this.cart.coupon.by_pass_min_cart != 0)
    }
  },
  watch: {
    loading: function(newValue, oldValue) {
      if (!newValue) {
        this.$store.dispatch('setUserAction', '')
      }
    },
    selectedCategory: function(newValue, oldValue) {},
    selectedSubCategory: function(newValue, oldValue) {}
  },
  methods: {
    ...mapActions({
      setSelectedCart: action.SET_SELECTED_CART
    }),
    getComboItemName(menuItem, parent) {
      const { customize } = menuItem
      if (customize) {
        const { combos } = customize
        if (combos) {
          const combo = combos.filter(c => c.combo_uuid === parent.combo_uuid)[0]
          if (combo) {
            return combo.rules.name[this.lang]
          }
        }
      }
      return menuItem.name
    },
    updateDealsDetails(){
      if(Object.keys(this.dealsDetails).length == 0){
        return;
      }

      if(!this.cart || !Object.keys(this.cart).length <= 0 || this.cart.data.length<=0){
        return;
      }

      this.totalDeals = this.cart.data.filter(item => item.base_category.sku.toLowerCase() == "deals");
      let deals = Object.keys(this.dealsDetails);
      for(let deal in deals){
        let isExists = this.totalDeals.find(cartItem => deal ==  cartItem.combo_uuid);
        if(!isExists){
          delete this.dealsDetails[deals];
        }
      }
    },
    loadUpsellProduct() {
      if (
        this.cart &&
        Object.keys(this.cart).length > 0 &&
        this.cart.data.length > 0
      ) {

        this.totalDeals = this.cart.data.filter(item => item.base_category.sku.toLowerCase() == "deals");
        if(this.totalDeals.length > 0 && Object.keys(this.dealsDetails).length != this.totalDeals.length){
          this.totalDeals.forEach(deal => { // load all deals info
            this.loadDealsDetails(deal.combo_uuid);
          });
          return;
        }

        let availableCategories = this.$session.get('listCategory')
        // Iterate cart items and check below orders
        // 1. No Drinks -> Show Drinks
        // 2. No Side -> Show Side
        // 3. No Dessert -> Show Dessert
        // Don't suggest anything
        // below flags will be used to check in card items
        let cartHasDrinks = this.isCategoryExistsInCart(
          'drinks',
          this.cart.data
        )
        let cartHasAppetizers = this.isCategoryExistsInCart(
          'appetizers',
          this.cart.data
        )
        let cartHasDesserts = this.isCategoryExistsInCart(
          'desserts',
          this.cart.data
        )

        if (cartHasDrinks && cartHasAppetizers && cartHasDesserts) {
          // card contains all items so just return;
          this.hideUpSell()
          return
        }

        if (
          availableCategories &&
          Object.keys(availableCategories).length > 0
        ) {
          if (!cartHasDrinks) {
            // load drinks
            this.showUpSellItem('drinks')
            return
          }
          if (!cartHasAppetizers) {
            // load appetizers
            this.showUpSellItem('appetizers')
            return
          }

          if (!cartHasDesserts) {
            // load desserts
            this.showUpSellItem('desserts')
            return
          }
        }
      } else {
        this.hideUpSell()
      }
    },
    hideUpSell() {
      this.showUpsell = false
      this.upsellData = {}
      this.backgroundImage = 'url()'
    },
    showUpSellItem(categoryName) {
      let category = this.getCategory(categoryName)
      if(category && category.length > 0) {
        this.upsellCategoryName = categoryName
        if (category[0].uuid) {
          this.getMenuByCategory(category[0].uuid)
        } else {
          let categoryIndex = this.upsellCategoryOrder[category]
          if (categoryIndex == this.upsellCategoryOrder.length) {
            // reached last category
          } else {
            // try to load next category item.
            this.showUpSellItem(this.upsellCategoryOrder[categoryIndex + 1])
          }
        }
      }
    },
    isCategoryExistsInCart(categoryName,cartItems){
      let isCategoryAvailableInCart = false;
      for(let index in cartItems){
        let cartItem = cartItems[index];
        if(cartItem.base_category.sku.toLowerCase() == "deals"){  // this is deals item so iterate each group and check
          let dealsDetail = this.dealsDetails[cartItem.combo_uuid];
          if(dealsDetail){
            isCategoryAvailableInCart = this.isCategoryExistsInDeals(dealsDetail,categoryName);
            if(this.isCategoryExistsInDeals(dealsDetail,categoryName)) return true;
          }
        } else {
          let category = this.getCategory(cartItem.base_category.sku.toLowerCase())
          if(category && category.length > 0) {
            return true
          } else {
            return false
          }
        }
      }
      return isCategoryAvailableInCart;
    },
    getCategory(categoryName) {
      let availableCategories = this.$session.get('listCategory')
      return availableCategories.filter(category => {
        return category.slug.toLowerCase() == categoryName.toLowerCase()
      })
    },
    getMenuByCategory(categoryId) {
      let data = this.getMenuRequest(categoryId, null)
      data.single_type = 1
      let vm = this
      this.axios.getMenuByCategory(
        data,
        function(response) {
          let items = response.data.data.items
          if (items && items.length > 0) {
            vm.upsellData = items[0].singles[0]
            vm.showUpsell = true
            vm.backgroundImage = 'url()'
            if (vm.upsellData.image && vm.upsellData.image.desktop_thumbnail)
              vm.backgroundImage =
                'url(' + vm.upsellData.image.desktop_thumbnail + ')'
          } else {
            // there is no product under categroy try another Order
            vm.hideUpSell()
          }
        },
        function(error) {
          console.log('error happens')
        }
      )
    },
    loadDealsDetails (comboId){
      let data = {
        tier: this.$disposition.getByKey(this, 'tier', '1'),
        order_type: this.$disposition
                        .getByKey(this, 'orderType', 'D')
                        .charAt(0),
        outlet_code: this.$disposition.getByKey(this, 'outletCode', null),
        order_time: this.convertDateTimeToUTC(
          this.$disposition.getByKey(this, 'collectionTimeText', '')
        )
      }
      let vm = this;
      this.axios.getComboDetails(comboId, data, function(response) {
        let dealsDetails = response.data.data.item;
        vm.dealsDetails[comboId] = dealsDetails;
        // let isExists = vm.isCategoryExistsInDeals(dealsDetails,categoryName);
        // if(!isExists && !vm.showUpsell ){
        //   vm.showUpSellItem(categoryName);
        // }
        if(vm.totalDeals.length >0  && Object.keys(vm.dealsDetails).length == vm.totalDeals.length){
          vm.loadUpsellProduct();
        }
      });

    },
    isCategoryExistsInDeals(dealsDetails,categoryName){
      return dealsDetails.groups.find(
        deal => deal.items.find(
          item => item.category.sku == categoryName
        )
      );
    },
    /**
     * adding item to cart
     * */
    addToBasket() {
      this.$store.dispatch('setUserAction', this.ADD_TO_CART_UPSELL)
      this.item = this.upsellData
      if (!this.checkPosition(this)) {
        this.$router.push({ name: 'home' })
      } else {
        let pro = {
          id: this.item.uuid,
          name: this.item.name,
          price: 0,
          quantity: 1,
          item: this.item,
          first: {}
        }
        let firstSelected = {}
        let secondSelected = {}
        let price = 0
        // add to cart
        let item = {
          group: {},
          selling_start: this.item.selling_start,
          products: []
        }
        item.group['outlet_code'] = this.getOutletCode(this)
        item.group['tier'] = this.$cart.tier
        item.group['order_type'] = this.$cart.order_type
        item.group['order_time'] = this.getTimePickerDate(this)
        item.group['product_type'] = this.item.product_type
        item.group['combo_id'] = null
        item.group['quantity'] = 1
        item.price = price
        item.item = this.lodash.cloneDeep(this.item)
        item.first = firstSelected
        item.second = secondSelected
        let product = {}
        product.category_id = this.$cart.category_id
        product.combo_group_id = null
        product.product_id = this.item.uuid
        product.first_id = this.selected_crust_size
                         ? this.selected_crust_size.puuid
                         : null
        product.second_id = this.selected_crust_size
                          ? this.selected_crust_size.cuuid
                          : null
        product.toppings = {}
        product.toppings.remove = []
        product.toppings.extra = []
        item.products.push(product)
        // add to cart
        let upSell = []
        if (this.item.first_layers && this.item.first_layers.length > 0) {
          for (let f of this.item.first_layers) {
            if (f.uuid == this.selected_crust_size.puuid) {
              for (let s of f.second_layers) {
                let price = parseFloat(f.price) + parseFloat(s.price)
                if (price > this.selected_crust_size.price) {
                  let crustUpSell = {}
                  let itemUpSell = {
                    group: {},
                    selling_start: this.item.selling_start,
                    products: []
                  }
                  itemUpSell.group['outlet_code'] = this.getOutletCode(this)
                  itemUpSell.group['tier'] = this.$cart.tier
                  itemUpSell.group['order_type'] = this.$cart.order_type
                  itemUpSell.group['order_time'] = this.getTimePickerDate(this)
                  itemUpSell.group['product_type'] = this.item.product_type
                  itemUpSell.group['combo_id'] = null
                  itemUpSell.group['quantity'] = 1
                  crustUpSell.category_id = this.$cart.category_id
                  crustUpSell.combo_group_id = null
                  crustUpSell.product_id = this.item.uuid
                  crustUpSell.first_id = f.uuid
                  crustUpSell.second_id = s.uuid
                  crustUpSell.toppings = {}
                  crustUpSell.toppings.remove = []
                  crustUpSell.toppings.extra = []
                  itemUpSell.products.push(crustUpSell)
                  itemUpSell.name = f.name + ' ' + s.name
                  itemUpSell.price = price
                  itemUpSell.item = this.lodash.cloneDeep(this.item)
                  itemUpSell.first = f
                  itemUpSell.second = s
                  upSell.push(itemUpSell)
                }
              }
            }
          }
        }
        if (upSell.length > 0) {
          item.price = price
          this.$modal.show(
            ModalUpSell,
            {
              items: upSell,
              previousItem: item
            },
            {
              class: 'modal-upsell',
              transition: 'nice-modal-fade',
              overlayTransition: 'overlay-fade',
              clickToClose: false,
              name: 'close-modal-upsell'
            }
          )
        } else {
          this.$cart.insert(this, item)
          pro.price = price
          pro.category = this.upsellCategoryName || ''
          pro.first = firstSelected
          this.eventTrackingAddToCart(
            this,
            pro,
            'main',
            this.$const.CARD_ITEM_TYPE.SINGLE
          )
        }
      }
    },
    isSpecialTopping(topping) {
      return topping.special == 1 || topping.special == 2
    },
    getRequiredMinCart() {
      let amount = 0
      let minCart = this.$disposition.getByKey(this, 'minCart', '')
      if (
        this.empty(minCart) ||
        !minCart.hasOwnProperty('D') ||
        minCart.D <= 0
      ) {
        amount = 0
      } else {
        amount = minCart.D || 0
      }
      if (!isNaN(amount)) {
        amount = this.formatNumDisplay(amount)
      }
      return amount
    },
    checkMinCart() {
      let orderType = this.$disposition.getByKey(this, 'orderType', '')
      if (orderType === this.$const.CARRYOUT) {
        return true
      }
      let minCart = this.$disposition.getByKey(this, 'minCart', '')
      if (
        this.empty(minCart) ||
        !minCart.hasOwnProperty('D') ||
        minCart.D <= 0
      ) {
        return true
      }
      minCart = minCart.D
      let cartTotal = this.cart.total - this.cart.delivery_fee
      if (cartTotal >= minCart && cartTotal > 0) {
        return true
      }

      return false
    },
    checkMaxCart() {
      if (this.cart.total <= this.$const.MAX_CART_VALUE) {
        return true
      }

      return false
    },
    /**
     * Add item to cart
     * */
    ModifyQuantity(item, event) {
      event.stopPropagation()

      let vm = this
      if (item.coupon_code) {
        let coupon = null
        let list = vm.cart.coupon.data

        if (list) {
          for (let c in list) {
            if (list[c].code === item.coupon_code) {
              coupon = list[c]
              break
            }
          }
        }

        if (coupon) {
          if (
            vm.lodash.includes(
              this.$const.COUPON_TYPES.FREE_GIFT,
              coupon.discount_type
            )
          ) {
            return false
          } else if (
            vm.lodash.includes(
              this.$const.COUPON_TYPES.CIRCLE_DISCOUNT,
              coupon.discount_type
            ) &&
            coupon.discount_quantity &&
            !coupon.discount_value
          ) {
            return false
          }
        }
      }

      this.$modal.show(
        ModalUpdateCart,
        {
          title: item.name,
          min: 1,
          max: 50,
          value: item.quantity,
          inputDisabled: true,
          update: function(data) {
            if (data && data > 0) {
              this.$cart.updateQuantity(this, {
                item_id: item.id,
                quantity: data
              })
              let product = this.generateTrackingInfo(item, data)
              if (data > item.quantity) {
                this.eventTrackingAddToCart(
                  this,
                  product,
                  'main',
                  item.product_type
                )
              } else {
                let variants = {}
                let uuid = item.combo_uuid
                if (item.items.length > 0) {
                  for (let i in item.items) {
                    if (item.items[i].layer_info && item.items[0].layer_info.first) {
                      variants.id = item.items[0].layer_info.first.first_id
                      variants.name = item.items[0].layer_info.first.name
                      if (item.items[0].layer_info.second) {
                        variants.id += '-' + item.items[0].layer_info.second.second_id
                        variants.name += '-' + item.items[0].layer_info.second.name
                      }
                    }
                    uuid = uuid || item.items[i].product_uuid
                  }
                }
                let pro = {
                  id: uuid,
                  name: product.name,
                  variants: variants,
                  price: product.item_price,
                  quantity: product.quantity,
                  category: product.category
                }
                this.eventTrackingRemoveFromCart(pro)
              }
            }
          }
        },
        {
          class: 'modal-update-cart'
        },
        {
          'before-open': function() {
            document.body.classList.add('modal-open')
          },
          'before-close': function() {
            document.body.classList.remove('modal-open')
          }
        }
      )
    },

    formatName(data, isHalf = false) {
      let text = ''
      if (isHalf) {
        text += '(' + data.layer_info.first.name + ') ' + data.name
      } else {
        if (data.items.length) {
          if (data.items[0].layer_info && data.items[0].layer_info.first) {
            text = data.items[0].layer_info.first.name
          }

          text += ' ' + (data.name || '')
        }
      }

      return text
    },
    formatSize(val) {
      if (val) {
        val = val.toLowerCase()
        if (val === 'medium') {
          return 'M size'
        } else if (val === 'large') {
          return 'L size'
        }
      }
    },
    AddToCart(data) {
      // console.log(this.$cart.get(this))
      // let item = {
      //   "group": {},
      //   "products": []
      // }

      // item.group['category_id'] = this.menu.uuid
      // item.group['outlet_id'] = this.menu.uuid
      // item.group['tier'] = this.$cart.tier
      // item.group['order_type'] = this.$cart.order_type
      // item.group['order_time'] = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
      // item.group['product_type'] = this.menu.product_type
      // item.group['combo_id'] = ''
      // item.group['quantity'] = 1
      // item.group['cart_id'] = ''

      // let products = {}
      // products.product_id = data.id
      // products.first_id = this.choosedFirst.uuid
      // products.second_id = this.choosedSecond.uuid
      // products.toppings = {}
      // products.toppings.remove = this.$cart.getToppingById(this.removeYourTopping)
      // products.toppings.extra = this.$cart.getToppingById(this.addExtraTopping)
      // item.products.push(products)

      // FIXME: Uncomment when debugging
      // console.log(data)
      // console.log('We will work on it later')
      // console.log('Single -> add to cart from here')
      // console.log('Combo -> link to combo page')
      // console.log('Haft & haft -> Link to haft & haft page')

      // Single -> add to cart from here
      // Combo -> link to combo page
      // Haft & haft -> Link to haft & haft page
      // let item = {
      //   uid: data.id,
      //   menu_id: data.id,
      //   menu_name: data.name,
      //   thumbnail: '',
      //   quantity: 1,
      //   price: data.promotionprice,
      //   plucode: 0,
      //   first_name: '',
      //   second_name: '',
      //   isCombo: 'N'
      // }
      // this.$cart.insert(this, item)
    },
    /**
     * Remove item from cart
     * */
    removeCart(item) {
      this.$store.dispatch('setUserAction', this.REMOVE_FROM_CART)
      this.$cart.remove(this, item.id)
      let variants = []
      let uuid = item.combo_uuid
      if (item.items.length > 0) {
        for (let i in item.items) {
          if (item.items[i].layer_info && item.items[0].layer_info.first) {
            variants.id = item.items[0].layer_info.first.first_id
            variants.name = item.items[0].layer_info.first.name
            if (item.items[0].layer_info.second) {
              variants.id += '-' + item.items[0].layer_info.second.second_id
              variants.name += '-' + item.items[0].layer_info.second.name
            }
          }
          uuid = uuid || item.items[i].product_uuid
        }
      }
      let pro = {
        id: uuid,
        name: item.name,
        variants: variants,
        quantity: item.quantity,
        price: item.item_price,
        category: item.base_category.short_name
      }
      this.eventTrackingRemoveFromCart(pro)
    },
    removeCoupon(code) {
      this.$cart.removeCoupon(this, { coupon_code: code })
    },
    /**
     * Show modal voucher
     * */
    showModalVoucher() {
      if (!this.checkPosition(this)) {
        this.$router.push('/?isShowWarning=1')
        return false
      }
      this.$modal.show(
        voucher,
        {},
        {
          class: 'modal-coupon'
        },
        {
          'before-open': function() {
            document.body.classList.add('modal-open')
          },
          'before-close': function() {
            document.body.classList.remove('modal-open')
          }
        }
      )
    },
    /**
     * Add item to cart
     * */
    addItemOtherComponents(item) {
      this.$cart.insert(this, item)
    },
    /**
     * Show/Hide cart info
     * */
    showHideCart(flag) {
      this.isCartOpen = flag
      if (flag) {
        document.body.classList.add('modal-open-cart')
      } else {
        document.body.classList.remove('modal-open-cart')
      }
      this.resizeCart()
    },
    /**
     * Trigger resize window
     * */
    resizeCart() {
      if (document.documentElement.clientWidth > 768) {
        this.addonsBottom = 75
      } else {
        if (this.isCartOpen) {
          let container = this.$el.querySelector('.checkout-out')
          let nav = document.body.querySelector('#nav')
          this.addonsBottom = container.scrollHeight + nav.scrollHeight - 15
        } else {
          this.addonsBottom = -15
        }
      }
    },
    preCheckout() {
      let hasDrink = this.isCategoryExistsInCart('drinks', this.cart.data)
      let hasDessert = this.isCategoryExistsInCart('desserts', this.cart.data)
      if (!hasDrink || !hasDessert) {
        this.$modal.show(ModalUpsellConfirmation, {
          hasDrink,
          hasDessert,
          checkout: this.checkout,
          toggleCart: this.showHideCart
        })
      } else {
        this.checkout()
      }
    },
    /**
     * Check out
     * */
    checkout() {
      if (this.cart.total == 0) {
        if (!this.isCouponBypassingMinimumCart) {
          this.$notify({
            type: 'warn',
            title: this.$t('please_add_more_food')
          })

          return false
        }
      }

      if (!this.checkMinCart()) {
        if (!this.isCouponBypassingMinimumCart) {
          this.$notify({
            type: 'warn',
            title: this.$t('format_mincart', {
              currency: this.$const.CURRENCY,
              amount: this.getRequiredMinCart()
            })
          })

          return false
        }
      }

      if (!this.checkMaxCart()) {
        this.$notify({
          type: 'warn',
          title: this.$t('please_remove_some_food')
        })

        return false
      }

      document.body.classList.remove('modal-open-cart')
      this.$router.push({ name: 'checkout' })
    },
    getTotalItem() {
      let quantity = 0
      for (let i in this.cart.data) {
        quantity += this.cart.data[i].quantity
      }
      return quantity
    },
    editSingle(menu, isHalf = false) {
      if (menu.coupon_code) {
        return false
      }

      this.setSelectedCart({
        cartId: menu.id,
        productUuid:
          menu.items && menu.items.length ? menu.items[0].product_uuid : null,
        isHalf: isHalf,
        menu: menu
      })
    },
    editCombo(menu) {
      if (menu.coupon_code) {
        return false
      }
      this.$router.push({
        name: 'combo-detail',
        params: { id: menu.combo_uuid, ssid: menu.id }
      })
    },
    /**
     * Show modal popup
     * */
    show(item, itemId, coupon, isHalf = false) {
      if (!isHalf) {
        this.$modal.show(
          ModalPopup,
          {
            menu: item,
            coupon: coupon || '',
            scrollable: false,
            mfirst: {},
            msecond: {},
            itemId: itemId,
            extraCheese: []
          },
          {
            class: 'modal-menu',
            transition: 'nice-modal-fade',
            overlayTransition: 'overlay-fade',
            clickToClose: false,
            name: 'close-modal-menu'
          },
          {
            'before-open': this.beforeOpen,
            'before-close': this.beforeClose
          }
        )
        let pro = {
          id: item.id,
          name: item.name,
          price: this.checkNum(item.min_price),
          brand: 'Google',
          cat: item.category_id,
          variant: '',
          position: 1
        }
      } else {
        this.$modal.show(
          ModalPopupHalf,
          {
            menu: this.item,
            scrollable: false,
            mfirst:
              this.mode === 'list' &&
              this.$route.params.slug == this.$const.MENU_PIZZA
              ? this.selectedVariant.first || {}
              : {},
            msecond:
              this.mode === 'list' &&
              this.$route.params.slug == this.$const.MENU_PIZZA
              ? this.selectedVariant.second || {}
              : {},
            extraCheese: []
          },
          {
            class: 'modal-menu',
            transition: 'nice-modal-fade',
            overlayTransition: 'overlay-fade',
            clickToClose: false,
            name: 'close-modal-menu'
          },
          {
            'before-open': this.beforeOpen,
            'before-close': this.beforeClose
          }
        )
      }
    },
    /**
     * Trigger before open modal popup
     * */
    beforeOpen(event) {
      document.body.classList.add('modal-open')
    },
    /**
     * Trigger before close modal popup
     * */
    beforeClose(event) {
      document.body.classList.remove('modal-open')
    }
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.carousel-control.left {
  top: 50px;
  margin-left: -3px !important;
}

.carousel-control.right {
  top: 50px;
  margin-right: -3px !important;
}

.vueperslides {
  padding-left: 20px;
  padding-right: 20px;
  height: 80px;
  width: calc(100% + 20px);
  margin-left: -10px;
  background-color: #fff;
}

.min-cart {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0px 9px;
}
.upsell-header {
  font-size: 17px;
  font-family: 'SharpSemiBold Sans';
  position: initial;
  color: #333;
}
.btn-upsell-disabled {
  background: #e5edef;
}

.btn-upsell-disabled:hover {
  background: #e5edef;
  color: white !important;
  border: 1px solid white !important;
}
</style>
