<template>
  <div id="top-main">
    <div class="inner-main" :dir="uiDirection">
      <header-main></header-main>
      <nav-category mode="main"></nav-category>
      <slot></slot>
    </div>
    <cart></cart>
    <chat></chat>
  </div>
</template>
<style lang='scss'>
  @import '../assets/css/category.css';
</style>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import HeaderMain from '../components/partials/HeaderMain.vue'
import NavCategory from '../components/partials/NavCategory.vue'
import Cart from '../components/partials/Cart.vue'
import Chat from '../components/partials/Chat.vue'

export default {
  computed: {
    ...mapGetters({
      uiDirection: 'uiDirection'
    }),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  mounted () {
    let body = document.body

    body.classList.add('mainpage')
  },
  components: {
    HeaderMain,
    NavCategory,
    Cart,
    Chat
  }
}
</script>
